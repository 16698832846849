export const BASE_URL = "https://as1.api.voucherify.io";
export const APP_ID = "a271f749-b81d-4ff3-98e9-4c80e896fb5c";
export const APP_TOKEN = "21677e1f-f6a3-42e8-bd9f-76a5b8ff829c";

export const GET_CAMPAIGN_LIST = "/v1/campaigns";
export const VOUCHERS_BY_CAMP = "/v1/campaigns/{camp_name}/vouchers";
export const GET_VOUCHER_LIST = "/v1/vouchers";
export const CREATE_CUSTOMER = "/v1/customers";
export const CREATE_PUBLLICATION = "/v1/publications";
export const REDEEM = "/v1/vouchers/{code}/redemption";
export const CUSTOMER_ENDPOINT = "/v1/customers";
